<script>
import ProductCard from '../product-card/comp_ProductCard.vue'
export default {
	lang: 'shop',
	extends: ProductCard,
	props: {
		checkedModel: Boolean,
		product: Object,
	},
	model: {
		prop: 'checkedModel',
		event: 'updateModel',
	},
	data() {
		return {
			checked: false,
		}
	},
	watch: {
		checked(value) {
			this.$emit('updateModel', value)
		},
	},
}
</script>
<template>
	<v-card elevation="3" @click="checked = !checked">
		<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
		<div class="d-flex white pa-3">
			<div class="pr-2">
				<v-checkbox v-model="checked" @click.stop="null" class="mt-0" />
			</div>
			<div>
				<div class="font-2 font-weight-bold mb-2">{{ 'Añadir' | lang }} {{ product.name }}</div>
				<div class="font-2 mb-2">
					+ <PriceText :currency-margin="0" :amount="price" :currency="currency" />
				</div>
				<div style="word-break: break-word">
					{{ product.info ? product.info.description : '' }}
				</div>
			</div>
		</div>
	</v-card>
</template>
