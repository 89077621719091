<script>
import { get } from 'vuex-pathify'
import { createPortals } from '@/__shared/utils/lib_utils'
import { generateProductSchema } from './product-schema'

const ProductPortals = createPortals(require.context('@/site', true, /portal_Product(-.+)?\.vue/))

export default {
	lang: 'shop',
	components: { ProductPortals },
	metaInfo() {
		let title = '...'
		let description = ''
		let script = []
		let link = []
		if (this.selectedVariant) {
			title = this.product.name
			description = this.product.info?.description || this.product.name
			script.push({
				innerHTML: JSON.stringify(generateProductSchema(this.product, this.selectedVariant), null, 2),
				type: 'application/ld+json',
				body: true,
			})
			link.push({ rel: 'canonical', href: window.location.href })
		}
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
			script,
			link,
		}
	},
	data() {
		return {
			product: {},
			relatedProducts: [],
			loading: true,
			selectedVariantId: null,
			selectedVariant: null,
			customization: {},
			validation: {},
		}
	},
	computed: {
		routeParams: get('route@params'),
		routeVariantId: get('route@query.variant'),
		routeAddToCart: get('route@query.add-to-cart'),
		pageLoading() {
			return this.loading
		},
	},
	watch: {
		'routeParams.id'() {
			this.loadData()
		},
		selectedVariantId(value) {
			this.selectVariant(value)
		},
	},
	methods: {
		loadData() {
			let { id, urlName } = this.routeParams
			this.product = {}
			this.selectedVariant = null

			this.$shopApi.get({
				loading: (v) => (this.loading = v),
				url: `/catalog/product/${id}`,
				onSuccess: async ({ data, options }) => {
					let { product } = data
					if (!product) {
						//404
						return
					}

					if (product.urlName != urlName) {
						this.$router.replace({
							name: 'product',
							params: { id, urlName: product.urlName },
							query: this.$route.query,
						})
					}

					this.product = product
					this.relatedProducts = data.relatedProducts
					this.selectVariant(this.routeVariantId)
					this.autoAddToCart()
					this.$eventer.trigger('page:view')
					this.$prerenderReady()
				},
			})
		},
		selectVariant(variantId) {
			if (variantId && variantId == this.selectedVariant?.id) return

			let variant = null
			let isDefaultVariant = false
			if (variantId) variant = this.product.variants.find((variant) => variant.id == variantId)
			if (!variant) {
				isDefaultVariant = true
				variant = this.product.variants.find((variant) => variant.main)
				if (!variant) variant = this.product.variants[0]
			}

			if (this.routeVariantId != variant.id) {
				if (isDefaultVariant && this.routeVariantId) {
					this.$router.replace({
						query: { variant: undefined },
						params: { savePosition: true },
					})
				} else if (this.selectedVariant || this.routeVariantId) {
					this.$router.replace({
						query: { variant: variant.id },
						params: { savePosition: true },
					})
				}
			}

			this.selectedVariant = variant
			this.selectedVariantId = variant.id
			this.$eventer.trigger('product:view', {
				variant: this.selectedVariant,
				product: this.product,
			})
		},
		async autoAddToCart() {
			if (this.routeAddToCart != 1) return
			await this.$shopApi.post({
				url: '/cart/item',
				loading: false,
				data: {
					refType: 'product',
					refId: this.selectedVariant.id,
					qty: 1,
				},
				onSuccess: async ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
			await this.$router.push({ query: { ...this.$route.query, 'add-to-cart': undefined } })
			this.$store.set('shop/cartDrawer', true)
		},
	},
	created() {
		this.loadData()
	},
}
</script>

<template>
	<div
		v-if="loading"
		class="d-flex justify-center py-16 mt-8"
		:style="{ 'min-height': 'calc(100vh - 100px)' }"
	>
		<v-progress-circular :size="70" :width="7" color="primary" indeterminate />
	</div>
	<div v-else>
		<ProductPortals />
		<Container :fluid="$vuetify.breakpoint.xs" class="mt-2 mt-sm-8">
			<v-row>
				<v-col cols="12" md="7">
					<ProductImagesCarousel :images="product.images" />
					<portal-target name="Product.below-images" multiple />
				</v-col>
				<v-col cols="12" md="5">
					<div class="px-6 px-sm-0">
						<v-row>
							<v-col cols="10">
								<div class="text-h4 font-weight-bold">
									{{ product.name }}
								</div>
								<SkuChip :sku="selectedVariant.sku" class="mt-2" />
							</v-col>
							<v-col cols="2" class="text-right">
								<ProductWishlistToggler :productId="product.id" :heartWidth="25" class="mr-3 mt-1" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div style="position: relative">
									<VariantsSelector v-model="selectedVariantId" :product="product" class="pb-3" />
									<PriceLayout
										:prev-price="selectedVariant.pvPrice.prevPrice"
										:price="selectedVariant.pvPrice.price"
										:discount-pct="selectedVariant.pvPrice.discountPct"
										:unit-metric="product.packMetric ? product.unitMetric : null"
										:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
										price-class="font-8"
										class="pb-3"
									/>
									<div class="py-2 font-1">
										<ProductStockAvailabilityMessage :stock="selectedVariant.stock" class="py-1" />
										<div class="d-flex align-center py-1" v-if="selectedVariant.type == 'digital'">
											<v-icon class="mr-2">mdi-cloud-download</v-icon>
											{{ 'Producto digital descargable' | lang }}
										</div>
									</div>
									<portal-target name="Product.actions" multiple>
										<ProductActions
											:product="product"
											:variant="selectedVariant"
											:customization="customization"
											@validation="validation = $event"
											class="py-3"
										/>
									</portal-target>
									<portal-target name="Product.below-actions" multiple />
								</div>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</Container>
		<portal-target name="Product.info" multiple />
		<div class="py-12" v-if="relatedProducts.length">
			<Container class="pb-6 font-4 font-weight-bold text-center font-header">
				TAMBIÉN TE PUEDE INTERESAR
			</Container>
			<ProductsCarousel :products="relatedProducts" list-id="related-products" />
		</div>
	</div>
</template>
