<script>
import _ from 'lodash'

export default {
	props: {
		showDialog: Boolean,
		images: Array,
		indexValue: Number,
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	data() {
		return {
			currentIndex: this.indexValue,
			mountCarousel: false,
			cImages: [],
		}
	},
	watch: {
		indexValue(value) {
			this.currentIndex = value || 0
		},
		currentIndex(value) {
			if (value === null || value === undefined || !this.cImages[value]) return
			this.$emit('updateIndexValue', value)
			this.$set(this.cImages[value], 'scale', 0)
		},
		showDialog(value) {
			if (value) this.cImages = _.cloneDeep(this.images)
			setTimeout(() => (this.mountCarousel = value), 50)
		},
	},
	methods: {
		closeDialog() {
			this.$emit('closeDialog')
		},
		setImageScale(image) {
			let { scale = 0 } = image
			scale += 1
			if (scale > 2) scale = 0
			this.$set(image, 'scale', scale)
		},
	},
}
</script>

<template>
	<v-dialog :value="showDialog" persistent dark fullscreen transition="fade-transition">
		<div class="d-flex align-center justify-center overlay">
			<Button class="place-up" absolute top right icon x-large @click="closeDialog" dark>
				<v-icon x-large>mdi-close</v-icon>
			</Button>
			<Carousel
				v-if="mountCarousel"
				style="height: 100%"
				v-model="currentIndex"
				:items="cImages"
				:options="{
					arrows: { md: true },
					arrowsMargin: 40,
					arrowsInset: true,
					viewportStyle: 'height: 100%',
				}"
				:flicking-options="{ align: 'center', bound: true }"
			>
				<template #panel="{ item: image, active }">
					<div
						class="d-flex justify-center align-center py-4 img"
						style="height: 100%"
						:class="{ scale1: image.scale == 1, scale2: image.scale == 2, active }"
					>
						<Media
							:src="image.big"
							height="100%"
							width="100%"
							scale-down
							loader
							@click="setImageScale(image)"
						/>
					</div>
				</template>
			</Carousel>
		</div>
	</v-dialog>
</template>

<style scoped>
.place-up {
	z-index: 999;
}
.overlay {
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
}
.img {
	transform-origin: center;
	transition: 0.3s all;
}
.img.active.scale1 {
	transform: scale(1.2);
}
.img.active.scale2 {
	transform: scale(1.4);
}
</style>
